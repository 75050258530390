import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "./index.css";
import CrediCardView from "./CreditCardView";
import styles from './styles.module.css'
import * as serviceWorker from "./serviceWorker";

Sentry.init({
  dsn: "https://d95b930a888540e9aaf13c5172991cc6@o506512.ingest.sentry.io/5638127",
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <div id="main" className={`isolate-inheritance ${styles.theme}`}>
      <CrediCardView />
    </div>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
