export function replaceAll(
  src: string,
  oldStr: string,
  newStr: string
): string {
  return src.split(oldStr).join(newStr);
}

export function base64UrlToJson(base64Urltoken: string) {
  const base64Token = replaceAll(replaceAll(base64Urltoken, "_", "/"), "-", "+");
  return JSON.parse(atob(base64Token));
}

export function getParam(name: string, defaultValue?: string): string {
  const value = new URLSearchParams(window.location.search).get(name);
  if (value === null) {
    if (defaultValue !== undefined) {
      return defaultValue;
    } else if (window.location.hostname === "localhost") {
      return "e30="; // '{}' encoded in base64
    } else {
      throw new Error(
        `Parameter ${name} has not been provided in the query string`
      );
    }
  }
  return value;
}

export interface CustomerAddress {
  country: string;
  postCode: string;
  state: null | string;
  street: string;
  subStreet: null | string;
  town: string;
}

export function getCustomerAddress(): CustomerAddress {
  const rawCustomerAddress = getParam("customerAddress");
  try {
    return base64UrlToJson(rawCustomerAddress) as CustomerAddress;
  } catch (e) {
    throw new Error("Query string param 'customerAddress' is incorrect");
  }
}
