import React, { useEffect, useState } from "react";
import stylesCommon from "../styles.module.css";
import InputText from "../InputText";
import ButtonAction from "../ButtonAction";
import {
  initialize,
  FormState,
  isFormValid,
  getErrors,
  defaultFormState,
} from "./moonpayForm";
import { getParam, getCustomerAddress, CustomerAddress } from "./urlParamUtils";
import { onFormSubmit, SubmitResponse } from "./networkComms";
import { countries, USstates, CAstates } from "./moonpayCountryData";

const BodyCreditCard: React.FC = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [billingAddressProvided, setBillingAddressProvided] = useState(false);
  const [isAddressFilled, setIsAddressFilled] = useState(billingAddressProvided);
  const [formState, setFormState] = useState<FormState>(defaultFormState);
  const [address, setAddress] = useState({
    street: "",
    subStreet: null,
    town: "",
    postCode: "",
    state: "",
    country: "",
  });
  const [form, setForm] = useState<{
    submit: (
      customerAddress: CustomerAddress,
      onSubmit: (status: number, response: SubmitResponse) => void
    ) => void;
  }>()
  const [color] = useState("#" + getParam("color", "31a5ff"))
  const [customerId] = useState(getParam("customerId"))
  const [transactionId] = useState(getParam("transactionId"))
  const [customerAddress] = useState(getCustomerAddress())
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (initialized) {
      return;
    }
    setInitialized(true);
    setForm(initialize(customerId, setFormState));
    setAddress({ ...address, 'country': countries[0].code, 'state': USstates[0].code });
    window.addEventListener("message", (message) => {
      if (message.data === "reset") {
        setIsLoading(false);
      }
    });
  }, [address, customerId, initialized]);

  useEffect(() => {
    setIsAddressFilled(!billingAddressProvided || !Object.entries(address).some(([key, value]) => {
      if (value === null) return false
      if (key === 'state' && address['country'] !== 'USA') return false
      if (!!value) return false
      return true
    }))
  }, [billingAddressProvided, address])

  const onActionButton = () => {
    setIsLoading(true);
    window.parent.postMessage({ type: 'INIT' }, "*")
    if (isFormValid(formState)) {
      if(form === undefined){
        throw new Error("form has not been initialized")
      }
      form.submit(billingAddressProvided ? address : customerAddress, onFormSubmit(transactionId, () => { setIsLoading(false) }));
    }
  };

  const colorStyle = { "--primary-color": color } as React.CSSProperties;
  let addressForm = undefined;
  if (billingAddressProvided) {
    const fields = [
      ["Street", "street"],
      ["Town", "town"],
      ["Postcode", "postCode"],
      ["Country", "country"],
    ] as [string, "street" | "town" | "postCode" | "country" | "state"][];
    if (address.country === "USA" || address.country === "CAN") {
      fields.push(["State", "state"]);
    }
    addressForm = fields.map(([label, name]) => (
      <InputText
        className={stylesCommon["body__child"]}
        inputValue={address[name]}
        onInputChange={(event) => {
          setAddress({ ...address, [name]: event.target.value });
        }}
        label={label}
        key={name}
        error={undefined}
        possibleValues={
          name === "country"
            ? countries
            : name === "state"
            ? address.country==="USA"
              ? USstates
              : CAstates
            : undefined
        }
      ></InputText>
    ));
  }

  return (
    <div className={stylesCommon.view} >
      <main className={stylesCommon.body} style={colorStyle}>
        <InputText
          className={stylesCommon["body__child"]}
          label="Card number"
          id="cc-number"
          error={getErrors(formState.number)}
        />
        <div
          className={`${stylesCommon["body__child"]} ${stylesCommon["row-fields"]}`}
        >
          <InputText
            className={stylesCommon["row-fields__child"]}
            label="Expiry date"
            id="cc-expiration"
            error={getErrors(formState.expiryDate)}
          />
          <InputText
            className={stylesCommon["row-fields__child"]}
            label="CCV"
            id="cc-cvc"
            error={getErrors(formState.cvc)}
          />
        </div>
        <label className={`${stylesCommon["body__child"]} ${stylesCommon["label"]}`}>
          <input
            type="checkbox"
            checked={billingAddressProvided}
            onChange={(event) =>
              setBillingAddressProvided(event.target.checked)
            }
          />
            &nbsp;I want to use a different billing address from my main one
        </label>
        {addressForm && <div className={stylesCommon["body__child"]}>{addressForm}</div>}
        <div className={`${stylesCommon["body__child"]} ${stylesCommon.grow}`}>
          <ButtonAction
            onClick={onActionButton}
            text={isLoading ? "Processing..." : "Continue"}
            disabled={isLoading || !isFormValid(formState) || !isAddressFilled}
          />
        </div>
      </main>
    </div>
  );
};

BodyCreditCard.defaultProps = {};

export default BodyCreditCard;
